/* eslint-disable max-lines */
import { useCallback, useContext, useEffect, useState } from 'react';
import { FRAME_COLORS, getMixMaterialSwatchesOptions, METAL_BASE_FRAMES, NET_NEW_ACETATE, NEW_COLORS } from '@utils/constants';
import {
	Button,
	Flex,
	Loading,
	Material,
	Modal,
	ModalContext,
	PairLogo,
	Refresh,
	SwatchController,
	TakePhoto,
} from '@components';
import { getBaseName } from '@utils/strings';
import { useDebounce, useTranslation } from '@utils/hooks';
import { VTOProps } from '@ts/index';
import { BASE_NO_PRESCRIPTION_REGEX } from '@utils/constants/base-skus';
import { normalizeHandle } from '@utils/shopify';
import VTOProduct from './VTOProduct';
import VTOButton from './VTOButton';
import { captureVTO, fitmixStyles, fmKey } from './config';
import styles from './VTO.module.scss';

const getTranslatedTexts = translator => {
	return {
		close: translator('close-uppercase'),
		takePhoto: translator('take-photo'),
		refresh: translator('refresh'),
		baseFrameColor: translator('base-frame-color'),
		buildYourProduct: (productName: string) => translator('build-your-product', { productName }),
	};
};

const VTO = ({
	FM,
	state,
	setState,
	products,
	pathInfo,
	showBaseFrameOption = true,
	setFrameColor,
	showMaterialOption = false,
}: VTOProps) => {
	const { translator, locale } = useTranslation();
	const translations = getTranslatedTexts(translator);
	const adjustedProducts = state.product.handle.includes('mixed-material')
		? products.filter(p => p.handle.includes('mixed-material'))
		: products;

	const debouncedCaptureVTO = useDebounce(captureVTO, 1000);
	const [selectedMaterial, setSelectedMaterial] = useState(
		[FRAME_COLORS.MIX_BLACK_GOLD, FRAME_COLORS.MIX_CRYSTAL_SILVER].includes(state.frame) ? state.frame : 'Acetate'
	);
	const MIX_MATERIAL_SWATCHES_OPTIONS = getMixMaterialSwatchesOptions(locale);

	const vtoParams = {
		apiKey: fmKey,
		onSnapshot: debouncedCaptureVTO,
		onAgreePrivacyTerms: () => setPrivacyTermsAccepted(true),
		css: fitmixStyles,
	};

	const [FitMix, setFitMix] = useState(null);
	const [privacyTermsAccepted, setPrivacyTermsAccepted] = useState(false);

	const productURL = `${pathInfo}/${normalizeHandle(state.product.handle.replace('-mixed-material', ''), true)}?frameColor=${state.variant.option}`;

	const open = useContext(ModalContext);
	// remove this when the VTO sku values are updated
	const colorRefreshSkuCorrections: Record<string, string> = {
		'0109_046_ODF': '0129_046_ODF',
		'0109_047_ODF': '0129_047_ODF',
		'0129_046_ODF': '0109_046_ODF',
		'0129_047_ODF': '0109_047_ODF',
	};

	useEffect(() => {
		const baseFrameName = state.product.name.split(' ')[1];
		const color = state.variant.option;
		const originalSku = state.variant.sku.match(BASE_NO_PRESCRIPTION_REGEX)[0];
		const finalSku =
			selectedMaterial === 'Acetate' &&
			(!NET_NEW_ACETATE.includes(baseFrameName as (typeof NET_NEW_ACETATE)[number]) ||
				NEW_COLORS.includes(color as (typeof NEW_COLORS)[number]))
				? originalSku
				: state.variant.sku;
		FitMix && FitMix.setFrame(colorRefreshSkuCorrections[finalSku] ?? finalSku);
	}, [FitMix, state]);

	useEffect(() => {
		const widgetCreator = window?.FitMix ?? FM;

		if (!widgetCreator) return;

		if (!FitMix && open) {
			const fmInstance = widgetCreator.createWidget('my-fitmix-container', vtoParams, () => {
				fmInstance.startVto('live');
				setFitMix(fmInstance);
			});
		}
	}, [open, FM]);

	const availableOptions = state.product.variants.map(variant => variant.option);
	const swatchOptions = selectedMaterial.includes('Mixed Material')
		? MIX_MATERIAL_SWATCHES_OPTIONS.filter(option => option.metal).map(option => option.metal)
		: MIX_MATERIAL_SWATCHES_OPTIONS.filter(option => availableOptions.includes(option.acetate)).map(option => option.acetate);

	const closeVTO = () => {
		FitMix.stopVto();
	};

	const getVTOSnapshot = () => {
		FitMix.getSnapshot();
	};

	const resetLiveMode = () => {
		FitMix.restartVto();
	};

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const handleSwatchChange = ({ option, type }) => {
		setState({
			type: 'frame',
			option,
		});

		const material = selectedMaterial === 'Acetate' ? 'Acetate' : option;
		setSelectedMaterial(material);
	};

	const handleClose = ({ option, type }) => {
		if (setFrameColor && type === 'frame') {
			setFrameColor(option);
		}

		if (selectedMaterial) {
			const isMixedMaterialColor = [FRAME_COLORS.MIX_BLACK_GOLD, FRAME_COLORS.MIX_CRYSTAL_SILVER].includes(option);
			setSelectedMaterial(isMixedMaterialColor ? state.variant.option : 'Acetate');
		}
		closeVTO();
	};

	const handleMaterialChange = useCallback(
		material => {
			setSelectedMaterial(material);
			if (material === 'Acetate') {
				if (state.variant.option === FRAME_COLORS.MIX_CRYSTAL_SILVER) {
					setState({
						type: 'frame',
						option: FRAME_COLORS.CRYSTAL_CLEAR,
					});
				} else if (state.variant.option === FRAME_COLORS.MIX_BLACK_GOLD) {
					setState({
						type: 'frame',
						option: FRAME_COLORS.BLACK,
					});
				}
			} else {
				if (state.variant.option === FRAME_COLORS.CRYSTAL_CLEAR) {
					setState({
						type: 'frame',
						option: FRAME_COLORS.MIX_CRYSTAL_SILVER,
					});
				} else if (state.variant.option === FRAME_COLORS.BLACK) {
					setState({
						type: 'frame',
						option: FRAME_COLORS.MIX_BLACK_GOLD,
					});
				} else {
					setState({
						type: 'frame',
						option: FRAME_COLORS.MIX_CRYSTAL_SILVER,
					});
				}
			}
		},
		[state]
	);

	return (
		<div className={styles[`vto-popup`]} data-vto-popup>
			<div className={`${styles['vto-popup__pair-logo']} ${styles['vto-icon']}`}>
				<PairLogo />
			</div>

			<Modal.Close asChild>
				<Button
					extraClasses={`${styles['vto-popup__close']}`}
					label={translations.close}
					color={'transparent-light'}
					onClick={() => closeVTO()}
				/>
			</Modal.Close>

			{!FitMix && <Loading />}
			<section className={styles['vto-popup__fitmix-container']} id='my-fitmix-container' />

			<section className={styles['relative']}>
				{privacyTermsAccepted && (
					<div className={styles['vto-icon__container']}>
						<VTOButton clickEvent={getVTOSnapshot} text={translations.takePhoto}>
							<TakePhoto />
						</VTOButton>
						<VTOButton clickEvent={resetLiveMode} text={translations.refresh}>
							<Refresh />
						</VTOButton>
					</div>
				)}

				<div className={styles['select-frame__carousel']}>
					{showBaseFrameOption &&
						adjustedProducts.map(product => {
							return (
								<VTOProduct
									key={`${product.handle} VTO link`}
									product={product}
									active={state.product.name === product.name}
									vtoState={state}
									setVtoState={setState}
								/>
							);
						})}
				</div>

				<div className={styles['vto-popup__options-container']}>
					<SwatchController
						alignment='center'
						callback={handleSwatchChange}
						name={state.product.name}
						options={swatchOptions}
						selected={state.variant.option}
						title={translations.baseFrameColor}
						type='frame'
						label={translations.baseFrameColor}
					/>
					{showMaterialOption &&
						METAL_BASE_FRAMES.includes(state.product.name.split(' ')[1] as (typeof METAL_BASE_FRAMES)[number]) && (
							<Flex column align='center' gap={3} style={{ paddingBottom: '1.6rem' }}>
								<Material callback={handleMaterialChange} vtoState={state} selectedMaterial={selectedMaterial} />
							</Flex>
						)}
					{!showBaseFrameOption ? (
						<Modal.Close asChild>
							<Button
								color='green'
								data-name='build-flow'
								data-build-your={state.product.name}
								fullWidth
								label={translations.buildYourProduct(getBaseName(state.product.name, false))}
								onClick={() => handleClose({ option: state.variant.option, type: 'frame' })}
							/>
						</Modal.Close>
					) : (
						<Button
							color='green'
							data-name='build-flow'
							data-build-your={state.product.name}
							fullWidth
							onClick={closeVTO}
							href={productURL}
							label={translations.buildYourProduct(getBaseName(state.product.name, false))}
						/>
					)}
				</div>
			</section>
		</div>
	);
};

export default VTO;
